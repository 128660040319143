import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import AboutHero from '../components/organisms/AboutHero';
import AwardsSection from '../components/organisms/AwardsSection';
import CoreValuesSection from '../components/organisms/CoreValuesSection';
import JoinUsSection from '../components/organisms/JoinUsSection';
import LeadershipTeam from '../components/organisms/LeadershipTeam';
import OurStorySection from '../components/organisms/OurStorySection';

const AboutPage = ({ data: { data } }) => (
  <Layout>
    <Seo post={data} />

    <AboutHero {...data.about.aboutHero} />
    <AwardsSection title={data.about.awardsTitle} awards={data.about.awards} />
    <OurStorySection {...data.about.ourStory} />
    {/*<SolvingTodaysChallengesSection {...data.about.solvingTodaysChallenges} />*/}
    <CoreValuesSection {...data.about.coreValues} />
    <LeadershipTeam
      title={data.about.leadershipTitle}
      team={data.about.leadershipTeam}
    />
    <JoinUsSection {...data.about.joinUsSection} />
  </Layout>
);

export const pageQuery = graphql`
  query AboutPageQuery {
    data: wpPage(slug: { eq: "about" }) {
      title
      ...SEO
      about {
        aboutHero {
          title
          body
        }
        awardsTitle
        awards {
          labels {
            label
          }
          logo {
            ...Image
          }
        }
        ourStory {
          title
          body
          image1 {
            ...Image
          }
          image2 {
            ...Image
          }
        }
        solvingTodaysChallenges {
          title
          body
          bulletPoints {
            label
          }
        }
        coreValues {
          lead
          title
          values {
            title
            body
            icon {
              ...Image
            }
          }
        }
        leadershipTitle
        leadershipTeam {
          photo {
            ...Image
          }
          name
          title
          linkedinUrl
        }
        joinUsSection {
          title
          body
          cta {
            to
            label
          }
          photo {
            ...Image
          }
        }
      }
    }
  }
`;

export default AboutPage;
