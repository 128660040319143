import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Image from '../base/Image';

interface AboutHeroProps {
  title: string;
  body: string;
}

const AboutHero: React.FC<AboutHeroProps> = ({ title, body }) => {
  const { topLeft, topRight } = useStaticQuery(
    graphql`
      query {
        topLeft: file(name: { eq: "about-ribbon-top-left" }) {
          ...FileImage
        }
        topRight: file(name: { eq: "about-ribbon-bottom-right" }) {
          ...FileImage
        }
      }
    `,
  );
  return (
    <section className="relative pb-20 overflow-hidden text-center bg-tertiary pt-18 lg:pt-36 lg:pb-40">
      <div className="absolute top-0 left-0 hidden w-32 h-auto md:block xl:w-64">
        <Image data={{ localFile: topLeft }} />
      </div>
      <div className="absolute bottom-0 right-0 hidden w-48 h-auto md:block xl:w-96">
        <Image data={{ localFile: topRight }} />
      </div>
      <div className="u-container">
        <svg
          width="30"
          height="34"
          viewBox="0 0 30 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto mb-5"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.554688 16.1048L18.7508 18.2855L24.8281 12.2261L5.64889 9.86667L0.554688 16.1048Z"
            fill="url(#paint0_linear3333)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.71884 0.428986L15.3899 6.59565C15.5865 6.72077 15.7116 6.91739 15.7116 7.14976C15.7116 7.38213 15.6044 7.57875 15.3899 7.70387L0.947343 16.0333C0.750725 16.1585 0.518358 16.1585 0.321739 16.0333C0.107246 15.9082 0 15.7116 0 15.4792V3.16377C0 2.00193 0.571981 1.01884 1.57295 0.428986C2.57391 -0.142995 3.71788 -0.142995 4.71884 0.428986ZM25.6498 12.5121L28.0271 13.8884C29.028 14.4604 29.6 15.4614 29.6 16.6232C29.6 17.785 29.028 18.7681 28.0271 19.358L4.71884 32.7995C3.71788 33.3715 2.57391 33.3715 1.57295 32.7995C0.571981 32.2275 0 31.2266 0 30.0647V27.3121C0 26.5077 0.393237 25.8106 1.10821 25.3995L23.4512 12.4942C24.1483 12.101 24.9527 12.101 25.6498 12.5121Z"
            fill="url(#paint1_linear44444)"
          />
          <defs>
            <linearGradient
              id="paint0_linear3333"
              x1="1.62769"
              y1="8.03717"
              x2="24.3116"
              y2="20.6121"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#430000" />
              <stop offset="0.2962" stopColor="#7D0000" />
              <stop offset="0.4426" stopColor="#940000" />
              <stop offset="0.7363" stopColor="#940000" />
              <stop offset="0.7847" stopColor="#940000" />
              <stop offset="0.7931" stopColor="#8F0000" />
              <stop offset="0.8405" stopColor="#750000" />
              <stop offset="0.8897" stopColor="#630000" />
              <stop offset="0.9415" stopColor="#590000" />
              <stop offset="1" stopColor="#550000" />
            </linearGradient>
            <linearGradient
              id="paint1_linear44444"
              x1="0.545402"
              y1="16.6017"
              x2="22.7919"
              y2="16.7061"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#C30000" />
              <stop offset="0.1672" stopColor="#CC0710" />
              <stop offset="0.4586" stopColor="#D91126" />
              <stop offset="0.7405" stopColor="#E01733" />
              <stop offset="1" stopColor="#E31937" />
            </linearGradient>
          </defs>
        </svg>

        <h1
          className="max-w-2xl mx-auto mb-6 xl:max-w-4xl u-h1"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div
          className="max-w-xl mx-auto text-lg lg:text-xl lg:max-w-2xl text-body-copy"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </div>
    </section>
  );
};

export default AboutHero;
