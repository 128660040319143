import React from 'react';
import { IImage } from '../../interfaces/image';
import Image from '../base/Image';

interface AwardsSectionProps {
  title: string;
  awards: Array<{
    labels: Array<{ label: string }>;
    logo: IImage;
  }>;
}

const AwardsSection: React.FC<AwardsSectionProps> = ({ title, awards }) => (
  <section className="pt-18 pb-14 md:py-28">
    <div className="text-center u-container">
      <h2
        className="mb-12 text-lg font-semibold md:text-xl"
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <div className="flex flex-wrap justify-center max-w-5xl md:mx-auto">
        {awards.map(({ labels, logo }, index) => (
          <div key={`logo-${index}`} className="w-1/2 mb-8 lg:w-3/12">
            <Image data={logo} className="w-auto mx-auto mb-4 h-7 md:h-12" objectFit="contain" style={{maxWidth:'120px', width:'100%', }}/>
            <div className="text-xs leading-snug text-body-copy opacity-60 mb-0.5">
              {labels.map(({ label }, index) => (
                <div key={`label-${index}`}>{label}</div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default AwardsSection;
