import React from 'react';
import { IImage } from '../../interfaces/image';
import LinkedInIcon from '../atoms/LinkedInIcon';
import Slider from '../molecules/Slider';

interface LeadershipTeamProps {
  title: string;
  team: Array<{
    photo: IImage;
    name: string;
    title: string;
    linkedinUrl: string;
  }>;
}

const LeadershipTeam: React.FC<LeadershipTeamProps> = ({ title, team }) => {
  return (
    <section className="py-16 overflow-hidden bg-tertiary lg:py-32">
      <div className="u-container">
        <h2 className="items-end justify-between block mb-12 space-y-8 md:mb-16 lg:flex lg:space-y-0 u-h2">
          <div className="u-h2" dangerouslySetInnerHTML={{ __html: title }} />
        </h2>
      </div>
      <div className="relative overflow-visible u-container ">
        <Slider>
          {team.map(({ photo, name, title, linkedinUrl }, index) => (
            <div className="w-72" key={index} style={{ height: 440 }}>
              <div className="flex items-end w-full mb-5 pointer-events-none select-none bg-keyline h-80">
                {photo && (
                  <img
                    src={photo.sourceUrl}
                    alt={photo.altText}
                    className="w-full h-auto"
                  />
                )}
              </div>

              <div className="mb-1 text-2xl font-semibold pointer-events-none ">
                {name}
              </div>
              <div className="mb-5 pointer-events-none ">{title}</div>
              {linkedinUrl && (
                <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
                  <LinkedInIcon />
                </a>
              )}
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default LeadershipTeam;
