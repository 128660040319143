import React from 'react';
import { nl2br } from '../../helpers/text';
import { IImage } from '../../interfaces/image';
import Image from '../base/Image';
import FadeIn from '../molecules/FadeInWhenVisible';

interface OurStorySectionProps {
  title: string;
  body: string;
  image1: IImage;
  image2: IImage;
}

const OurStorySection: React.FC<OurStorySectionProps> = ({
  title,
  body,
  image1,
  image2,
}) => (
  <div className="overflow-hidden">
    <section className="relative justify-between lg:flex u-container-max ">
      <img
        src="/images/our-story-ribbon-left.png"
        alt=""
        className="absolute z-10 hidden w-auto -mr-44 lg:block top-16 right-full h-120"
      />
      <img
        src="/images/our-story-ribbon-right.png"
        alt=""
        className="absolute hidden h-auto -z-1 lg:block"
        style={{ top: 464, left: -22, width: 1600, maxWidth: 1600 }}
      />
      <div className="lg:w-1/2">
        <FadeIn className="w-full">
          <Image data={image1} className="w-full h-auto" />
        </FadeIn>
      </div>
      <div className="mt-12 lg:pl-24 lg:pr-20 lg:w-1/2 md:mt-0 md:mb-20 lg:mb-32">
        <div className="px-5 md:px-0">
          <h2 className="mt-6 mb-5 u-h2">{title}</h2>
          <div
            className="mb-12 leading-normal text-body-copy"
            dangerouslySetInnerHTML={{ __html: nl2br(body) }}
          />
        </div>
        <FadeIn className="w-full">
          <Image data={image2} className="w-full h-auto" />
        </FadeIn>
      </div>
    </section>
  </div>
);

export default OurStorySection;
