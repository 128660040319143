import React from 'react';
import { IImage } from '../../interfaces/image';
import CtaLink from '../atoms/CtaLink';

interface JoinUsSectionProps {
  title: string;
  body: string;
  cta: {
    to: string;
    label: string;
  };
  photo: IImage;
}

const JoinUsSection: React.FC<JoinUsSectionProps> = ({
  title,
  body,
  cta,
  photo,
}) => (
  <section className="relative justify-end pt-16 lg:flex lg:pt-32 ">
    <div className="inset-0 items-center lg:flex lg:absolute">
      <div className="flex mb-16 u-container lg:mb-0">
        <div className=" lg:w-1/2 lg:pr-40">
          <h2 className="u-h2">{title}</h2>
          <p className="mb-8 md:leading-8 md:text-lg text-body-copy">{body}</p>
          <CtaLink {...cta} />
        </div>
      </div>
    </div>

    <div className="w-full lg:w-1/2">
      <img
        src={photo.sourceUrl}
        alt={photo.altText}
        className="w-full h-auto"
      />
    </div>
  </section>
);

export default JoinUsSection;
