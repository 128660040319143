import React from 'react';
import { IImage } from '../../interfaces/image';

interface CoreValuesSectionProps {
  lead: string;
  title: string;
  values: Array<{
    title: string;
    body: string;
    icon: IImage;
  }>;
}

const CoreValuesSection: React.FC<CoreValuesSectionProps> = ({
  lead,
  title,
  values,
}) => (
  <section
    className="py-16 bg-cover md:py-32"
    style={{
      backgroundImage:
        'linear-gradient(137.62deg, #1DC8BE 14.54%, #1977F2 138.22%)',
    }}
  >
    <div className="u-container">
      <div
        className="text-white u-lead"
        dangerouslySetInnerHTML={{ __html: lead }}
      />
      <h2
        className="max-w-md mb-8 text-white u-h2 md:mb-14"
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {values.map(({ title, body, icon }, index) => (
          <div
            key={index}
            className="flex flex-col items-start p-8 bg-white rounded-md md:p-12 shadow-card"
          >
            <h3
              className="mb-8 text-2xl font-semibold"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div
              className="flex-grow leading-relaxed mb-13"
              dangerouslySetInnerHTML={{ __html: body }}
            />
            <img
              src={icon.sourceUrl}
              alt={icon.altText}
              className="w-auto h-12"
            />
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default CoreValuesSection;
